import React from 'react';

import "./style.scss";

export default function Title(props) {

  const { name } = props;

  return (
    <span className="title">{name}</span>
  )
}
