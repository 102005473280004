import React from 'react';
import Title from 'components/title';
import Section from 'components/sections';
import { ReactComponent as EarthWeb } from 'images/earth-web.svg';
import { ReactComponent as Hexegon } from 'images/hexegon.svg';
import { ReactComponent as CloudFile } from 'images/cloud-file.svg';
import { ReactComponent as EarthServer } from 'images/earth-server.svg';

import "./style.scss";

let data = [
  {
    text: "Project Management from A to Z",
    image: <EarthWeb />
  },
  {
    text: "Continuous App Development with Continuous Integration",
    image: <Hexegon />
  },
  {
    text: "Design and Implementation of Your Next App",
    image: <CloudFile />
  },
  {
    text: "Cloud Transformation",
    image: <EarthServer />
  },
];

export default function WhatSection() {

  return (
    <div id="what" className="what-section">
      <div className="what-section-skills">
        {data.map((e, key) =>
          <div className="box-container" key={key}>
            <div className="box-content">
              {e.image}
              <span>{e.text}</span>
            </div>
          </div>
        )}
      </div>
      <div className="what-section-information">
        <Title name="What" />
        <Section
          title="Consistency through experience"
          text={<>We are a full-stack web and mobile development company <br />
            that also offers training and consultancy for teams and individuals. <br />
            We offer modern reliable solutions for all your projects.</>}
        />
      </div>
    </div>
  )
}
