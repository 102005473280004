import React from 'react';
import HeroSection from 'components/sections/components/Hero';
import ClientSection from 'components/sections/components/Client';
import WhatSection from 'components/sections/components/What';
import HowSection from 'components/sections/components/How';
import Partners from 'components/partners';
import TeamSection from 'components/sections/components/Team';

function Home() {
  return (
    <>
      <HeroSection />
      <WhatSection />
      <HowSection />
      <ClientSection />
      <Partners />
      <TeamSection />
    </>
  )
}

export default Home;