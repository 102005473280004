import React from 'react'
import Logo from "components/logo";
import "./style.scss"
import Navigation from 'components/navigation';
import { Link } from 'react-router-dom';
export default function Header() {
  return (
    <div className="header">
      <Link to="/">
        <Logo />
      </Link>
      <Navigation />
    </div>
  )
}
