import Button from 'components/button';
import Title from 'components/title';
import React from 'react';

import "./contact.scss";

export default function Contact() {
  return (
    <div className="contact">
      <div className="form-container">
        <Title name="Contact" />
        <form action="#" className="form">
          <div className="input-container">
            <input className="input" type="text" name="name" placeholder="Your Name" />
            <span class="border"></span>
          </div>
          <div className="input-container">
            <input className="input" type="text" name="email" placeholder="Your Email" />
            <span class="border"></span>
          </div>
          <div className="input-container">
            <textarea className="input" name="message" placeholder="Your Message"></textarea>
            <span class="border"></span>
          </div>
          <div class="contact-button">
            <Button name="Submit" />
          </div>
        </form>
      </div>
    </div>
  )
}
