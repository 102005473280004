import React from 'react';
import Title from 'components/title';
import Section from "components/sections";

import "./style.scss"

export default function ClientSection() {
  return (
    <div className="clients-section">
      <Title name="Clients" />
      <Section
        title="Always up to date with your needs"
        text={<>We are a global team of professionals and offer our services worldwide. Here are some <br /> of our clients that we developed for and succeeded together with.</>}
      />
    </div>
  )
}
