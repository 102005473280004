import React from 'react';
import Section from 'components/sections';
import { ReactComponent as HeroBg } from 'images/hero-bg.svg';


import "./style.scss"
export default function HeroSection() {
  return (
    <div className="hero-section">
      <Section
        title={<>Innovative <br /> high quality solutions</>}
        text={<>Ecma brings your ideas to life with it's team of professionals. <br /> From inception to completion we value your input.</>}
      />
      <HeroBg />
    </div>
  )
}