import React from 'react'
import Title from 'components/title'
import Section from 'components/sections';
import { ReactComponent as EarthWeb } from 'images/earth-web.svg';
import { ReactComponent as Api } from 'images/api.svg';
import { ReactComponent as Customer } from 'images/customer.svg';
import { ReactComponent as Mobile } from 'images/mobile.svg';

import "./style.scss"

let data = [
  {
    text: "We build connected each other products",
    image: <EarthWeb />
  },
  {
    text: "We build and deliver powerful products",
    image: <Api style={{ zoom : 2.5 }} />
  },
  {
    text: "Customer Focussed",
    image: <Customer style={{ width : 100, margin : 20 }} />
  },
  {
    text: "Creating mobile solutions",
    image: <Mobile style={{ width : 60 }} />
  },
];

export default function HowSection() {
  return (
    <div id="how" className="how-section">
      <div className="how-section-skills">
        {data.map((e, key) =>
          <div className="box-container" key={key}>
            <div className="box-content">
              {e.image}
              <span>{e.text}</span>
            </div>
          </div>
        )}
      </div>
      <div className="how-section-information">
        <Title name="How" />
        <Section
          title={<>Joint development <br /> mutual success</>}
          text={<>We analyse your projects meticulously and develop them together <br />
            with you. We build efficient teams, scale them according to your <br />
            needs and consistently meet them at every turn.</>}
        />
      </div>
    </div>
  )
}
