import React from 'react';
import iconecwave from 'images/partners/iconecwave.png';
import turkcell from 'images/partners/turkcell.png';
import curationZone from 'images/partners/curationzone.png';
import scotty from 'images/partners/scotty.png';
import pro from 'images/partners/1337pro.png';

import "./style.scss";

let data = [
  {
    name: "Iconecwave",
    image: iconecwave
  },
  {
    name: "Turkcell",
    image: turkcell
  },
  {
    name: "Curation Zone",
    image: curationZone
  },
  {
    name: "Scotty",
    image: scotty
  },
  { 
    name: "1337Pro",
    image: pro
  }
];

export default function Partners() {
  return (
    <div className="partners">
      {data.map((e, key) =>
        <div className="partner-box" key={key}>
          <img src={e.image} title={e.name}/>
        </div>
      )}
    </div>
  )
}
