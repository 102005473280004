import React from 'react';
import Logo from "components/logo";
import { ReactComponent as Linkedin } from "images/linkedin.svg";
import { ReactComponent as Twitter } from "images/twitter.svg";

import "./style.scss";

let data = [
  {
    name: "Linkedin",
    image: <Linkedin />,
    url: "https://www.linkedin.com/company/ecmaco/"
  },
  {
    name: "Twitter",
    image: <Twitter />,
    url: "https://twitter.com/ecma_co"
  }
];

export default function Footer() {
  return (
    <div id="contact" className="footer">
      <div className="footer-content">
        <div className="left-side">
          <Logo />
          <h4>info@ecma.co</h4>
        </div>
        <div className="right-side">
          <div className="social-media">
            {data.map((e, key) =>
              <a href={e.url} target="_blank" key={key}>
                {e.image}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
