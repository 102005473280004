import React from 'react';

import "./style.scss";

export default function Section(props) {

  const { title, text } = props;

  return (
    <div className="section-container">
      <h4 className="section-title">{title}</h4>
      <p className="section-text">{text}</p>
    </div>
  )
}
