import React from 'react';
import Title from 'components/title';
import Section from 'components/sections';
import Button from 'components/button';

import RamesAliyev from 'images/team/rames.png';
import SerkanSepil from 'images/team/serkan.png';
import CihanYilmaz from 'images/team/cihan.png';
import MesutGok from 'images/team/mesut.png';
import MustafaTastepe from 'images/team/mustafa.png';
import Umit from 'images/team/umit.png';
import Cengizhan from 'images/team/cengizhan.png';
import Nihal from 'images/team/nihal.png';
import Emrah from 'images/team/emrah.png';
import Ebru from 'images/team/ebru.png';

import "./team.scss"

let data = [
  {
    image: Cengizhan,
    name: "Cengizhan Çalışkan",
    job: "Lead Engineer",
    social: [
      {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/cengizhancaliskan/"
      },
    ],
  },
  {
    image: CihanYilmaz,
    name: "Cihan Yilmaz",
    job: "Product Designer",
    social: [
      {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/cihany/"
      },
    ],
  },
  {
    image: Ebru,
    name: "Ebru Ören",
    job: "Marketing Executive",
    social: [
      {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/ebru-ören-808707169/"
      },
    ],
  },

  {
    image: Emrah,
    name: "Emrah Erkeç",
    job: "Product Designer",
    social: [
      {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/emrah-erkec/"
      },
    ],
  },
  
  {
    image: Nihal,
    name: "Nihal Köprülü",
    job: "Frontend Developer",
    social: [
      {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/nihalkoprulu/"
      },
    ],
  },
  {
    image: MesutGok,
    name: "Mesut Gök",
    job: "Software Engineer",
    social: [
      {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/mesutgok/"
      },
    ],
  },
  {
    image: MustafaTastepe,
    name: "Mustafa Taştepe",
    job: "Frontend Developer",
    social: [
      {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/mustafatastepe/"
      },
    ],
  },
  {
    image: RamesAliyev,
    name: "Rames Aliyev",
    job: "Software Engineer",
    social: [
      {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/ramesaliyev/"
      },
    ],
  },
  {
    image: SerkanSepil,
    name: "Serkan Sepil",
    job: "Team Lead & Project Manager",
    social: [
      {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/sepil/"
      },
    ],
  },
  {
    image: Umit,
    name: "Ümit Can Karabudak",
    job: "Cloud Engineer",
    social: [
      {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/umitkarabudak/"
      },
    ],
  },
  
];

export default function TeamSection() {
  return (
    <div id="team" className="team-section">
      <Title name="Team" />
      <Section
        title="Together, we are ahead of the curve"
        text={<>Teamwork is at the heart of everything we do. It drives our experienced professionals to explore the best solutions and always be <br /> innovative. We love being ahead of the curve and provide the latest technologies.</>}
      />


      <div className="team-members">
        {data.map((e, key) =>
          <div className="member-box" key={key}>
            <div className="member-image">
              <img src={e.image} alt="member-image" />
            </div>
            <div className="member-details">
              <span className="member-name">{e.name}</span>
              <span className="member-job">{e.job}</span>
              <span className="member-social">
                <a target="_blank" href={e.social[0].url}>{e.social[0].name}</a>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )

}