import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Header from "components/header";
import Footer from "components/footer";
import Contact from "pages/Contact";
import PlugBunny from "pages/PlugBunny";

import "./styles/style.scss";

function App() {
  return (
    <>
      <div className="app-container">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/plugbunny" element={<PlugBunny />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
