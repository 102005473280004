import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./style.scss";

let nav = [
  {
    id: 1,
    name: "Home",
    to: "/",
    path: "/",
  },
  {
    id: 2,
    name: "What",
    to: "/#what",
    path: "what",
  },
  {
    id: 3,
    name: "How",
    to: "/#how",
    path: "how",
  },
  {
    id: 4,
    name: "Team",
    to: "/#team",
    path: "team",
  },
];

export default function Navigation() {
  let location = useLocation();

  return (
    <div className="navigation">
      <nav>
        {nav.map((e, key) => (
          <a
            className={location.hash.indexOf(e.path) > 0 ? "active" : null}
            onClick={() => document.getElementById(e.path).scrollIntoView()}
            style={{ cursor: "pointer" }}
          >
            {e.name}
          </a>
        ))}
        {/*<Link className={location.pathname.indexOf('/') > 0 ? 'active' : null} to="/">Home</Link>
        <Link className={location.pathname.indexOf('what') > 0 ? 'active' : null} to="#what">What</Link>
        <Link className={location.pathname.indexOf('how') > 0 ? 'active' : null} to="#how">How</Link>
        <Link className={location.pathname.indexOf('team') > 0 ? 'active' : null} to="#team">Team</Link>
        <Link className={location.pathname.indexOf('contact') > 0 ? 'active' : null} to="/contact">Contact</Link>*/}
      </nav>
    </div>
  );
}
